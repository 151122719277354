import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView column center>
            <CFView pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView mv="5px">
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView row center alignCenter ph="45px" bg="rgb(0,0,0,.8)">
            <CFView column justifyStart>
              <CFText color="#D8B17C" style={{ fontSize: 30 }} raleway bold>
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView ml="20px" pulsate mt="8px">
              <OrderPickupButton />
            </CFView>
            <CFView ml="20px" mt="8px">
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
