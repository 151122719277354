import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFIcon,
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, mobileLogo, logoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/z1n6s0Q1U12JBeruNx0e/locations/0nBUuNbz0naRmB9PPMgD'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
          row
          center
          bg="rgb(0,0,0,.8)"
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          pv="5px"
        >
          <CFImage w="45%" src={logo} alt="Kamei Broadway Logo" />
          {/* <CFView pv="8px" ml="15px">
            <LocationSelect />
          </CFView> */}
          <CFView
            h="35px"
            br="30px"
            bc="white"
            bw="2.5px"
            ph="20px"
            center
            ml="10px"
          >
            <CFView white bold mb="2px">
              Broadway
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          h="55px"
          bg="rgb(0,0,0,.8)"
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          zIndex={98}
          w="100%"
        >
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            maxWidth="1400px"
            ph="30px"
          >
            <CFView row center>
              <CFImage
                h="45px"
                ml="15px"
                src={logo}
                alt="Kamei Broadway Logo"
                zIndex={98}
              />
              {/* <CFSelect selector={dispatch.restaurant.getHasMultipleLocations}>
                <CFView zIndex={99} ml="15px">
                  <LocationSelect />
                </CFView>
              </CFSelect> */}
              <CFView
                center
                br="20px"
                bc="white"
                bw="2px"
                pv="3px"
                ph="20px"
                ml="15px"
                pointer
                hover
                zIndex={99}
              >
                <CFView color="white" bold mb="2px">
                  Broadway
                </CFView>
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
