import React from 'react'
import { Parallax } from 'react-parallax'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  broadwayAbout,
  broadwayMobileAbout,
  dinner,
  dinnerText,
  drink,
  drinkText,
  happyHour,
  happyHourText,
  lunch,
  lunchText,
  richmondLunch,
  richmondDinner,
  richmondDrink,
  richmondHappyHour,
  richmondAbout,
  richmondMobileAbout,
  broadwayLunch,
  broadwayDinner,
  broadwayDrink,
  broadwayHappyHour,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let lunchMenu = null
        let drinkMenu = null
        let dinnerMenu = null
        let happyHourMenu = null
        let about = null
        let mobileAbout = null
        if (locationId === 'DDnXWES1uWIe30lXMh7o') {
          about = richmondAbout
          mobileAbout = richmondMobileAbout
          lunchMenu = richmondLunch
          dinnerMenu = richmondDinner
          drinkMenu = richmondDrink
          happyHourMenu = richmondHappyHour
        } else {
          about = broadwayAbout
          mobileAbout = broadwayMobileAbout
          lunchMenu = broadwayLunch
          dinnerMenu = broadwayDinner
          drinkMenu = broadwayDrink
          happyHourMenu = broadwayHappyHour
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView
                w="100%"
                column
                center
                m="0 auto"
                maxWidth="1400px"
                mt="-1px"
              >
                <CFImage src={mobileAbout} w="100%" alt="Promotions" />
                <CFView
                  column
                  justifyBetween
                  alignCenter
                  w="88%"
                  maxWidth="1300px"
                  pb="5%"
                  mt="-135%"
                >
                  <CFView row justifyBetween alignCenter>
                    <CFView
                      w="85%"
                      maxWidth="310px"
                      h="24vh"
                      maxHeight="255px"
                      image={`url(${lunch}) center / cover no-repeat`}
                    >
                      <CFView
                        m="0 auto"
                        w="80%"
                        h="100%"
                        row
                        center
                        h3
                        white
                        raleway
                        xBold
                      >
                        <CFLink target="_blank" href={lunchMenu}>
                          <CFImage src={lunchText} w="100%" alt="Promotions" />
                        </CFLink>
                      </CFView>
                    </CFView>
                    <CFView
                      w="85%"
                      maxWidth="310px"
                      h="24vh"
                      maxHeight="255px"
                      image={`url(${dinner}) center / cover no-repeat`}
                    >
                      <CFView
                        m="0 auto"
                        w="80%"
                        h="100%"
                        row
                        center
                        h3
                        white
                        raleway
                        xBold
                      >
                        <CFLink target="_blank" href={dinnerMenu}>
                          <CFImage src={dinnerText} w="100%" alt="Promotions" />
                        </CFLink>
                      </CFView>
                    </CFView>
                  </CFView>

                  <CFView row justifyBetween alignCenter>
                    <CFView
                      w="85%"
                      maxWidth="310px"
                      h="24vh"
                      maxHeight="255px"
                      image={`url(${drink}) center / cover no-repeat`}
                    >
                      <CFView
                        m="0 auto"
                        w="80%"
                        h="100%"
                        row
                        center
                        h3
                        white
                        raleway
                        xBold
                      >
                        <CFLink target="_blank" href={drinkMenu}>
                          <CFImage src={drinkText} w="100%" alt="Promotions" />
                        </CFLink>
                      </CFView>
                    </CFView>
                    <CFView
                      w="85%"
                      maxWidth="310px"
                      h="24vh"
                      maxHeight="255px"
                      image={`url(${happyHour}) center / cover no-repeat`}
                    >
                      <CFView
                        m="0 auto"
                        w="80%"
                        h="100%"
                        row
                        center
                        h3
                        white
                        raleway
                        xBold
                      >
                        <CFLink target="_blank" href={happyHourMenu}>
                          <CFImage
                            src={happyHourText}
                            w="100%"
                            alt="Promotions"
                          />
                        </CFLink>
                      </CFView>
                    </CFView>
                  </CFView>
                </CFView>
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView
                w="100%"
                column
                center
                m="0 auto"
                maxWidth="1400px"
                mt="-1px"
              >
                <CFImage src={about} w="100%" alt="Promotions" />
                <CFView
                  row
                  justifyBetween
                  alignCenter
                  w="88%"
                  maxWidth="1300px"
                  pb="5%"
                  mt="-28%"
                >
                  <CFView
                    w="24%"
                    maxWidth="310px"
                    h="37vh"
                    maxHeight="275px"
                    image={`url(${lunch}) center / cover no-repeat`}
                  >
                    <CFView
                      m="0 auto"
                      w="60%"
                      h="100%"
                      row
                      center
                      h3
                      white
                      raleway
                      xBold
                    >
                      <CFLink target="_blank" href={lunchMenu}>
                        <CFImage src={lunchText} w="100%" alt="Promotions" />
                      </CFLink>
                    </CFView>
                  </CFView>
                  <CFView
                    w="24%"
                    maxWidth="310px"
                    h="37vh"
                    maxHeight="275px"
                    image={`url(${dinner}) center / cover no-repeat`}
                  >
                    <CFView
                      m="0 auto"
                      w="60%"
                      h="100%"
                      row
                      center
                      h3
                      white
                      raleway
                      xBold
                    >
                      <CFLink target="_blank" href={dinnerMenu}>
                        <CFImage src={dinnerText} w="100%" alt="Promotions" />
                      </CFLink>
                    </CFView>
                  </CFView>
                  <CFView
                    w="24%"
                    maxWidth="310px"
                    h="37vh"
                    maxHeight="275px"
                    image={`url(${drink}) center / cover no-repeat`}
                  >
                    <CFView
                      m="0 auto"
                      w="60%"
                      h="100%"
                      row
                      center
                      h3
                      white
                      raleway
                      xBold
                    >
                      <CFLink target="_blank" href={drinkMenu}>
                        <CFImage src={drinkText} w="100%" alt="Promotions" />
                      </CFLink>
                    </CFView>
                  </CFView>
                  <CFView
                    w="24%"
                    maxWidth="310px"
                    h="37vh"
                    maxHeight="275px"
                    image={`url(${happyHour}) center / cover no-repeat`}
                  >
                    <CFView
                      m="0 auto"
                      w="60%"
                      h="100%"
                      row
                      center
                      h3
                      white
                      raleway
                      xBold
                    >
                      <CFLink target="_blank" href={happyHourMenu}>
                        <CFImage
                          src={happyHourText}
                          w="100%"
                          alt="Promotions"
                        />
                      </CFLink>
                    </CFView>
                  </CFView>
                </CFView>
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
