import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  locationMapBroadway,
  locationMapRichmond,
  mobileMap,
  mobileMapRichmond,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let locationLink = ''
        let mapImageMobile = null
        let mapImage = null
        if (locationId === 'DDnXWES1uWIe30lXMh7o') {
          locationLink = 'https://goo.gl/maps/dQjwiUKsHcaW6XKWA'
          mapImage = locationMapRichmond
          mapImageMobile = mobileMapRichmond
        } else {
          locationLink = 'https://goo.gl/maps/hyC2fqfHP9B9B78y7'
          mapImage = locationMapBroadway
          mapImageMobile = mobileMap
        }
        return (
          <CFView>
            <MobileScreen>
              <CFLink href={locationLink} target="_blank">
                <CFImage
                  src={mapImageMobile}
                  w="100%"
                  alt="Samurai Sushi Map"
                />
              </CFLink>
            </MobileScreen>
            <DefaultScreen>
              <CFLink href={locationLink} target="_blank">
                <CFImage src={mapImage} w="100%" alt="Samurai Sushi Map" />
              </CFLink>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
