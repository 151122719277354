import React from 'react'
import {
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  broadwayMobilePromotion,
  broadwayPromotions,
  richmondMobilePromotion,
  richmondPromotions,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let promotions = null
        let mobilePromotion = null
        if (locationId === 'DDnXWES1uWIe30lXMh7o') {
          promotions = richmondPromotions
          mobilePromotion = richmondMobilePromotion
        } else {
          promotions = broadwayPromotions
          mobilePromotion = broadwayMobilePromotion
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView column center textCenter>
                <CFView h1 xBold white zIndex={98}>
                  Kamei On Broadway
                </CFView>
                <CFView h4 white zIndex={98}>
                  Best Japanese Restaurant in Vancouver
                </CFView>
                <CFImage
                  mt="-50px"
                  zIndex={0}
                  src={mobilePromotion}
                  w="100%"
                  alt="Promotions"
                />
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView column center m="0 auto" w="100%" maxWidth="1400px">
                <CFView style={{ fontSize: 50 }} xBold white zIndex={98}>
                  Kamei On Broadway
                </CFView>
                <CFView style={{ fontSize: 25 }} white zIndex={98}>
                  Best Japanese Restaurant in Vancouver
                </CFView>
                <CFImage
                  src={promotions}
                  alt="Promotions"
                  w="100%"
                  mt="-70px"
                  zIndex={0}
                />
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
