import React from 'react'
import { css } from 'emotion'
import {
  CFView,
  CFImage,
  CFLink,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  broadwayFoodGallery,
  broadwayMobileGallery,
  richmondFoodGallery,
  richmondMobileGallery,
  press,
  mobilePress,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let foodGallery = null
        let mobileGallery = null
        if (locationId === 'DDnXWES1uWIe30lXMh7o') {
          foodGallery = richmondFoodGallery
          mobileGallery = richmondMobileGallery
        } else {
          foodGallery = broadwayFoodGallery
          mobileGallery = broadwayMobileGallery
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView column center>
                <CFImage src={mobileGallery} w="100%" alt="Food Gallery" />
                <CFView mt="-500px">
                  <CFView h4 white textCenter mh="15px" pt="25px">
                    Established in 1988, Kamei on Broadway is a Vancouver staple
                    that both locals and visitors have long enjoyed dining!
                  </CFView>
                  <CFView h4 white textCenter mh="15px" pv="25px">
                    Tucked away behind Broadway Plaza, is a fascinating
                    restaurant that offers delicious food and great deals on
                    drinks. Sweeping panoramic views of downtown while seated in
                    unique Japanese Tatami rooms or private booths. Featuring an
                    innovative Japanese signature menu. Kamei on Broadway uses
                    the highest quality seafood selected by our specially
                    trained Japanese chefs. We pride ourselves in using only the
                    freshest ingredients to ensure our customer’s delight! Truly
                    a hidden gem!
                  </CFView>
                  <CFImage src={mobilePress} alt="Food Gallery" w="100%" />
                  <CFView
                    mt="-550px"
                    pb="30px"
                    ph="5%"
                    column
                    center
                    textCenter
                  >
                    <CFView color="red" bold h2 mv="5px">
                      Vancouver Is Awesome
                    </CFView>
                    <CFView white h4 mb="30px" className={styles.press}>
                      <CFLink href="https://www.vancouverisawesome.com/food-and-drink/kamei-broadway-family-run-japanese-restaurant-vancouver-bc-5965437">
                        Hidden Gem Vancouver Japanese Restaurant: Kamei On
                        Broadway
                      </CFLink>
                    </CFView>
                    <CFView color="red" bold h2 mv="5px">
                      Daily Hive
                    </CFView>
                    <CFView white h4 mb="30px" className={styles.press}>
                      <CFLink href="https://dailyhive.com/vancouver/kamei-on-broadway">
                        Kamei on Broadway is a longtime Japanese restaurant in a
                        hidden location
                      </CFLink>
                    </CFView>
                  </CFView>
                </CFView>
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView column center maxWidth="1400px" w="100%" mt="-2px">
                <CFView style={{ fontSize: 2 }}>
                  Established in 1988, Kamei on Broadway is a Vancouver staple
                  that both locals and visitors have long enjoyed dining! Tucked
                  away behind Broadway Plaza, is a fascinating restaurant that
                  offers delicious food and great deals on drinks. Sweeping
                  panoramic views of downtown while seated in unique Japanese
                  Tatami rooms or private booths. Featuring an innovative
                  Japanese signature menu. Kamei on Broadway uses the highest
                  quality seafood selected by our specially trained Japanese
                  chefs. We pride ourselves in using only the freshest
                  ingredients to ensure our customer’s delight! Truly a hidden
                  gem!
                </CFView>
                <CFImage src={foodGallery} alt="Food Gallery" w="100%" />
                <CFImage src={press} alt="Food Gallery" w="100%" />
                <CFView mt="-380px" pb="60px" column center textCenter>
                  <CFView color="red" bold h4 mv="5px">
                    Vancouver Is Awesome
                  </CFView>
                  <CFView white h5 mb="30px" className={styles.press}>
                    <CFLink href="https://www.vancouverisawesome.com/food-and-drink/kamei-broadway-family-run-japanese-restaurant-vancouver-bc-5965437">
                      Hidden Gem Vancouver Japanese Restaurant: Kamei On
                      Broadway
                    </CFLink>
                  </CFView>
                  <CFView color="red" bold h4 mv="5px">
                    Daily Hive
                  </CFView>
                  <CFView white h5 mb="30px" className={styles.press}>
                    <CFLink href="https://dailyhive.com/vancouver/kamei-on-broadway">
                      Kamei on Broadway is a longtime Japanese restaurant in a
                      hidden location
                    </CFLink>
                  </CFView>
                </CFView>
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
const styles = {
  press: css({
    'a:Link': {
      color: 'white',
    },
    'a:visited': {
      color: 'white',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
}
