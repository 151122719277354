import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderDeliveryButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let reserveLink = ''
        if (locationId === 'DDnXWES1uWIe30lXMh7o') {
          reserveLink = `tel:${6042704846}`
        } else {
          reserveLink =
            'https://www.opentable.ca/restaurant/profile/1209916?ref=16423'
        }
        return (
          <CFView>
            <MobileScreen>
              <a href={reserveLink} target="_blank">
                <CFView hover>
                  <CFImage
                    src={orderDeliveryButton}
                    maxWidth="260px"
                    alt="About"
                  />
                </CFView>
              </a>
            </MobileScreen>
            <DefaultScreen>
              <a href={reserveLink} target="_blank">
                <CFView hover>
                  <CFImage
                    src={orderDeliveryButton}
                    maxWidth="230px"
                    alt="About"
                  />
                </CFView>
              </a>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
