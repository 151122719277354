import React, { useState, useEffect } from 'react'
import { css } from 'emotion'
import { CFView, CFImage, CFLink, PrimaryModal } from 'components'
import {
  dineOutButton,
  dineOutMenu,
  seasonalButton,
  seasonalMenu,
} from 'images'
import { dispatch } from 'store'

export default () => {
  const [showModal, setShowModal] = useState(true)

  useEffect(() => {
    const welcomeMessage = dispatch.restaurant.getWelcomeMessage()
    if (welcomeMessage.title) {
      setShowModal(true)
    }
  }, [])

  const closeModal = () => {
    setShowModal(false)
    // localStorage.setItem('welcomeSeen', true)
  }

  return (
    <PrimaryModal
      title="Feature Menus!"
      isOpen={showModal}
      onRequestClose={closeModal}
    >
      <CFView className={styles.container}>
        <CFView column w="100%">
          <CFView h3 black textCenter m="10px" w="90%">
            {dispatch.restaurant.getWelcomeMessage().body}
          </CFView>
          <CFView column center textCenter mv="15px">
            {/* <CFLink target="_blank" href={dineOutMenu}>
              <CFImage src={dineOutButton} alt="Promotions" w="50%" mb="10px" />
            </CFLink> */}
            <CFLink target="_blank" href={seasonalMenu}>
              <CFImage src={seasonalButton} alt="Promotions" w="50%" />
            </CFLink>
          </CFView>
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '640px',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  }),
}
